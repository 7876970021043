<template>
  <div class="vue-admin-beautiful-wrapper" :class="classObj">
    <div
      v-if="'horizontal' === layout"
      class="layout-container-horizontal"
      :class="{
        fixed: header === 'fixed',
        'no-tabs-bar': tabsBar === 'false' || tabsBar === false,
      }"
    >
      <div :class="header === 'fixed' ? 'fixed-header' : ''">
        <vab-top-bar />
        <div
          v-if="tabsBar === 'true' || tabsBar === true"
          :class="{ 'tag-view-show': tabsBar }"
        >
          <div class="vab-main">
            <vab-tabs-bar />
          </div>
        </div>
      </div>
      <div class="vab-main main-padding">
        <!-- <vab-ad /> -->
        <vab-app-main />
      </div>
    </div>
    <div
      v-else
      class="layout-container-vertical"
      :class="{
        fixed: header === 'fixed',
        'no-tabs-bar': tabsBar === 'false' || tabsBar === false,
      }"
    >
      <div
        v-if="device === 'mobile' && collapse === false"
        class="mask"
        @click="handleFoldSideBar"
      />
      <div id="solid_left">
        <sildBar />
      </div>

      <div class="vab-main" :class="collapse ? 'is-collapse-main' : ''">
        <div :class="header === 'fixed' ? 'fixed-header' : ''">
          <vab-nav-bar />

          <!-- <vab-tabs-bar v-if="tabsBar === 'true' || tabsBar === true" /> -->
        </div>
        <vab-app-main />
      </div>
    </div>
    <el-backtop />
  </div>
</template>

<script>
  import { Index_order_num } from '@/api/xgcApi'
  import { mapActions, mapGetters } from 'vuex'
  import { tokenName } from '@/config'
  import sildBar from '../components/VabSideBar/index.vue'
  import $ from 'jquery' // 引入jQuery
  import { Number } from 'core-js/web/timers'
  export default {
    name: 'Layout',
    components: { sildBar },
    data() {
      return {
        oldLayout: '',
        time: null,
      }
    },
    computed: {
      ...mapGetters({
        layout: 'settings/layout',
        tabsBar: 'settings/tabsBar',
        collapse: 'settings/collapse',
        header: 'settings/header',
        device: 'settings/device',
      }),
      classObj() {
        return {
          mobile: this.device === 'mobile',
        }
      },
    },
    beforeMount() {
      window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize)
      if (this.time) {
        clearInterval(this.time)
        this.time = null
      }
    },
    mounted() {
      let left = $('#solid_left')

      let bar_item = left.find('.el-menu-item')
      let bar_item_title = left.find('.el-submenu .el-submenu__title')
      bar_item.each((index, item) => {
        bar_item
          .eq(index)
          .append(`<span style="color:red;margin-left:5px;"></span>`)
        var span = bar_item.eq(index).find('span')
      })
      bar_item_title.each((index, item) => {
        bar_item_title
          .eq(index)
          .append(
            `<span style="color:red;font-size:28px;margin-left:5px;" ></span>`
          )
        var span = bar_item_title.eq(index).find('span')
      })
      this.time = setInterval(() => {
        this.loop()
      }, 7000)

      this.oldLayout = this.layout
      const userAgent = navigator.userAgent
      if (userAgent.includes('Juejin')) {
        this.$baseAlert(
          'vue-admin-beautiful不支持在掘金内置浏览器演示，请手动复制以下地址到浏览器中查看http://mpfhrd48.sanxing.uz7.cn/vue-admin-beautiful'
        )
      }
      const isMobile = this.handleIsMobile()
      if (isMobile) {
        if (isMobile) {
          //横向布局时如果是手机端访问那么改成纵向版
          this.$store.dispatch('settings/changeLayout', 'vertical')
        } else {
          this.$store.dispatch('settings/changeLayout', this.oldLayout)
        }
        this.$store.dispatch('settings/toggleDevice', 'mobile')
        setTimeout(() => {
          this.$store.dispatch('settings/foldSideBar')
        }, 2000)
      } else {
        this.$store.dispatch('settings/openSideBar')
      }
      this.$nextTick(() => {
        window.addEventListener(
          'storage',
          (e) => {
            if (e.key === tokenName || e.key === null) window.location.reload()
            if (e.key === tokenName && e.value === null)
              window.location.reload()
          },
          false
        )
      })
    },
    methods: {
      ...mapActions({
        handleFoldSideBar: 'settings/foldSideBar',
      }),
      loop() {
        let left = $('#solid_left')
        let bar_item = left.find('.el-menu-item')
        let bar_item_title = left.find('.el-submenu .el-submenu__title')

        let data = {
          orderListTime_k: localStorage.getItem('orderListTime_k')
            ? Number(localStorage.getItem('orderListTime_k'))
            : 0,
          orderListTime_J: localStorage.getItem('orderListTime_J')
            ? Number(localStorage.getItem('orderListTime_J'))
            : 0,
          buy_list_time_k: localStorage.getItem('buy_list_time_k')
            ? Number(localStorage.getItem('buy_list_time_k'))
            : 0,
          buy_list_time_J: localStorage.getItem('buy_list_time_J')
            ? Number(localStorage.getItem('buy_list_time_J'))
            : 0,
          buy_list_time_D: localStorage.getItem('buy_list_time_D')
            ? Number(localStorage.getItem('buy_list_time_D'))
            : 0,
          goods_list_time: localStorage.getItem('goods_list_time')
            ? Number(localStorage.getItem('goods_list_time'))
            : 0,
          reshoot_goods_time: localStorage.getItem('reshoot_goods_time')
            ? Number(localStorage.getItem('reshoot_goods_time'))
            : 0,
          kor_order_list_time: localStorage.getItem('kor_order_list_time')
            ? Number(localStorage.getItem('kor_order_list_time'))
            : 0,
        }
        Index_order_num(data).then((res) => {
          bar_item_title.each((index, item) => {
            let span = bar_item_title.eq(index).find('span')
            let item_title = span[0].innerHTML
            // res.data = {
            //   buy_jep:1,
            //   buy_kor:2,
            //   order_jep:3,
            //   order_kor:4,
            //   buy_behalf_pay:100,
            //   kor_order:12
            // }
            if (item_title == '包裹管理') {
              if (
                res.data.order_jep > 0 ||
                res.data.order_kor > 0 ||
                res.data.buy_jep > 0 ||
                res.data.buy_kor > 0 ||
                res.data.buy_behalf_pay > 0 ||
                res.data.goods_order_num > 0 ||
                res.data.reshoot_goods_order_num > 0
              ) {
                span.eq(1).text(`●`)
                // span.eq(1).attr("style", "line-height:5px; margin-left:5px; background: red;borde;padding:0 8px;border-radius: 15px;");
                span.eq(1).attr('style', 'color:red;margin-left:5px;')
              } else {
                span.eq(1).attr('style', '')
                span.eq(1).text(``)
              }
            }
            // 中到韩物流小红点提示
            if (item_title == '中到韩物流') {
              if (
                res.data.kor_order > 0
              ) {
                span.eq(1).text(`●`)
                span.eq(1).attr('style', 'color:red;margin-left:5px;')
              } else {
                span.eq(1).attr('style', '')
                span.eq(1).text(``)
              }
            }
          })

          bar_item.each((index, item) => {
            let span = bar_item.eq(index).find('span')
            let item_title = span[0].innerHTML
            if (item_title == '韩区代买管理') {
              if (res.data.buy_kor > 0) {
                span.eq(1).text(`(${res.data.buy_kor})`)
              } else {
                span.eq(1).text('')
              }
            }
            if (item_title == '日区代买管理') {
              if (res.data.buy_jep > 0) {
                span.eq(1).text(`(${res.data.buy_jep})`)
              } else {
                span.eq(1).text('')
              }
            }
            if (item_title == '韩国包裹管理') {
              if (res.data.order_kor > 0) {
                span.eq(1).text(`(${res.data.order_kor})`)
              } else {
                span.eq(1).text('')
              }
            }
            if (item_title == '日本包裹管理') {
              if (res.data.order_jep > 0) {
                span.eq(1).text(`(${res.data.order_jep})`)
              } else {
                span.eq(1).text('')
              }
            }
            if (item_title == '代付管理') {
              if (res.data.buy_behalf_pay > 0) {
                span.eq(1).text(`(${res.data.buy_behalf_pay})`)
              } else {
                span.eq(1).text('')
              }
            }
            if (item_title == '商品订单') {
              if (res.data.goods_order_num > 0) {
                span.eq(1).text(`(${res.data.goods_order_num})`)
              } else {
                span.eq(1).text(``)
              }
            }
            if (item_title == '补价订单') {
              if (res.data.reshoot_goods_order_num > 0) {
                span.eq(1).text(`(${res.data.reshoot_goods_order_num})`)
              } else {
                span.eq(1).text(``)
              }
            }
            if (item_title == '包裹列表') {
              if (res.data.kor_order > 0) {
                span.eq(1).text(`(${res.data.kor_order})`)
              } else {
                span.eq(1).text('')
              }
            }
          })
        })
      },
      handleIsMobile() {
        return document.body.getBoundingClientRect().width - 1 < 992
      },
      handleResize() {
        if (!document.hidden) {
          const isMobile = this.handleIsMobile()
          if (isMobile) {
            //横向布局时如果是手机端访问那么改成纵向版
            this.$store.dispatch('settings/changeLayout', 'vertical')
          } else {
            this.$store.dispatch('settings/changeLayout', this.oldLayout)
          }
          this.$store.dispatch(
            'settings/toggleDevice',
            isMobile ? 'mobile' : 'desktop'
          )
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  @mixin fix-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $base-z-index - 2;
    width: 100%;
    overflow: hidden;
  }

  .vue-admin-beautiful-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    .layout-container-horizontal {
      position: relative;

      // &.fixed {
      //   padding-top: calc(#{$base-top-bar-height} + #{$base-tabs-bar-height});
      // }
      &.fixed {
        padding-top: calc(#{$base-top-bar-height});
      }

      &.fixed.no-tabs-bar {
        padding-top: $base-top-bar-height;
      }

      ::v-deep {
        .vab-main {
          width: 100%;
          margin: auto;
        }

        .fixed-header {
          @include fix-header;
        }

        .tag-view-show {
          background: $base-color-white;
          box-shadow: $base-box-shadow;
        }

        .nav-bar-container {
          .fold-unfold {
            display: none;
          }
        }

        .main-padding {
          .app-main-container {
            // margin-top: $base-padding;
            margin-bottom: $base-padding;
            background: $base-color-white;
          }
        }
      }
    }

    .layout-container-vertical {
      position: relative;

      .mask {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $base-z-index - 1;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        background: #000;
        opacity: 0.5;
      }
      // &.fixed {
      //   padding-top: calc(#{$base-nav-bar-height} + #{$base-tabs-bar-height});
      // }

      &.fixed {
        padding-top: calc(#{$base-nav-bar-height});
      }

      &.fixed.no-tabs-bar {
        padding-top: $base-nav-bar-height;
      }

      .vab-main {
        position: relative;
        min-height: 100%;
        margin-left: $base-left-menu-width;
        background: #f6f8f9;
        transition: $base-transition;

        ::v-deep {
          .fixed-header {
            @include fix-header;

            left: $base-left-menu-width;
            width: $base-right-content-width;
            box-shadow: $base-box-shadow;
            transition: $base-transition;
          }

          .nav-bar-container {
            position: relative;
            box-sizing: border-box;
          }

          .tabs-bar-container {
            box-sizing: border-box;
          }

          .app-main-container {
            width: calc(100% - #{$base-padding} - #{$base-padding});
            margin: $base-padding auto;
            background: $base-color-white;
            border-radius: $base-border-radius;
          }
        }

        &.is-collapse-main {
          margin-left: $base-left-menu-width-min;

          ::v-deep {
            .fixed-header {
              left: $base-left-menu-width-min;
              width: calc(100% - 65px);
            }
          }
        }
      }
    }

    /* 手机端开始 */
    &.mobile {
      ::v-deep {
        .el-pager,
        .el-pagination__jump {
          display: none;
        }

        .layout-container-vertical {
          .el-scrollbar.side-bar-container.is-collapse {
            width: 0;
          }

          .vab-main {
            width: 100%;
            margin-left: 0;
          }
        }

        .vab-main {
          .fixed-header {
            left: 0 !important;
            width: 100% !important;
          }
        }
      }
    }

    /* 手机端结束 */
  }
</style>
